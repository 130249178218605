'use client';
import { confirmResetPassword, resetPassword } from '@aws-amplify/auth';
import { SignInOutput, signIn } from 'aws-amplify/auth';
import React, { useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { SubmitButton } from '#/packages/authentication/components/SubmitButton';
import { Alert, Button, FormField, TextInput } from '#/packages/ui';
import { Anchor } from '#/src/components/NavAnchor';

interface ConfirmPasswordFormProps {
  username: string;
  onNext: (output: SignInOutput) => void;
}

export default function ConfirmResetPasswordAndSignInForm(props: ConfirmPasswordFormProps) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm();

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      try {
        await confirmResetPassword({
          username: props.username,
          confirmationCode: data.code,
          newPassword: data.password
        });
        const signinRes = await signIn({ username: props.username, password: data.password });
        props.onNext(signinRes);
      } catch (e: any) {
        console.error(e);
        setError('root.apiError', { message: e.message });
      }
    },
    [props.username]
  );

  return (
    <form className='flex flex-col gap-y-4 mt-4' onSubmit={handleSubmit(onSubmit)}>
      <div className='text-sm text-gray-900'> Email: {props.username}</div>

      <FormField label={'Code'} id={'code'}>
        <TextInput
          color={'neutral'}
          placeholder={'Enter code'}
          id='code'
          {...register('code', { required: true })}
          type='text'
        />
        <Anchor size={'sm'} onClick={() => resetPassword({ username: props.username })}>
          Request new code
        </Anchor>
      </FormField>

      <FormField label={'New password'} id={'password'}>
        <TextInput
          id='password'
          color={'neutral'}
          placeholder={'Enter new password'}
          {...register('password', { required: true })}
          type='password'
        />
      </FormField>

      {errors.root?.apiError && <Alert>{errors?.root?.apiError?.message?.toString()}</Alert>}

      <SubmitButton isLoading={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Set new password'}
      </SubmitButton>
    </form>
  );
}
