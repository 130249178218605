'use client';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import {
  AuthProvider,
  useAnalytics,
  useIdentifyUser,
  useTrackUserAuth
} from '#/packages/analytics';
import { AuthenticationFlowType } from '#/packages/authentication/components/AuthenticationFlowType';
import { AuthRedirectingMessage } from '#/packages/authentication/components/AuthRedirecting';
import { useWorkspace } from '#/src/context/session/hooks/useWorkspace';

interface AuthDoneStepProps {
  nextUrl: string;
  type: AuthenticationFlowType;
  source?: string;
}

export default function AuthDoneStep(props: AuthDoneStepProps) {
  const analytics = useAnalytics();
  const navigate = useRouter();
  const setWorkspace = useWorkspace()[1];
  const [triggered, setTriggered] = useState(false);

  const identifyUser = useIdentifyUser();
  const trackUserAuth = useTrackUserAuth();

  useEffect(() => {
    if (!triggered) {
      setTriggered(true);

      identifyUser()
        .then(() => trackUserAuth({ provider: AuthProvider.EMAIL, source: props.source }))
        .then(() => setWorkspace(null))
        .then(() => navigate.push(props.nextUrl));
    }
  }, [analytics]);

  return <AuthRedirectingMessage />;
}
